





















































































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";

import { Input, Button, Table, TableColumn } from "element-ui";
import pagination from "@/components/UIComponents/Pagination.vue";

import {
  MetaModule as metaStore,
  DriverModule as driverStore,
} from "@/store/modules";

import { AdminRouter } from "@/utils/routePathContsant";
import helpers from "@/utils/helpers";
import { getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";

@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
    pagination,
  },
})
export default class DutyOfficeClass extends Vue {
  palikas: any[] = [];
  // driverPath = AdminRouter.DriverRegister;
  driverList: any[] = [];
  districtName: any = [];

  filter: {
    page: number;
    name?: string;
    company_name?: string;
    district?: string;
    palika?: string;
  } = {
    page: 1,
  };

  momentDate(date: any) {
    return helpers.toMomentFormat(date);
  }

  get AdminRouter() {
    return AdminRouter;
  }
  hasRoles(roles: string[]) {
    return helpers.hasRoles(roles);
  }

  get UserType() {
    return helpers.getUserType();
  }

  get districts() {
    return metaStore.districtL;
  }
  async created() {
    metaStore.loadDis();
    await driverStore.loadAllDriver(this.filter);
    this.driverList = driverStore.driverList;
  }

  onChange(e: any) {
    if (!e.isActive) {
      this.$confirm(
        `Are you sure you want to activate this driver '${e.fullName}'?`
      )
        .then((_) => {
          driverStore.approveDriver(e);
          this.$snotify.success("Driver Activated Successfully");
          driverStore.loadAllDriver();
        })
        .catch((_) => {});
    } else {
      this.$confirm(`Are you sure to deactivate this driver '${e.fullName}'?`)
        .then((_) => {
          driverStore.approveDriver(e);
          this.$snotify.success("Driver Deactivated Successfully");
          driverStore.loadAllDriver();
        })
        .catch((_) => {});
    }
    this.driverList = driverStore.driverList;
  }

  async filterPalika() {
    this.filter.palika = "";
    if (this.filter.district) {
      this.palikas = await metaStore.loadMuncipality(
        parseInt(this.filter.district)
      );
    }
  }
  async filterDutyOffice() {
    this.filter.page = 1;
    await driverStore.loadAllDriver(this.filter);
    this.driverList = driverStore.driverList;
  }
  async clearFilter() {
    this.filter = {
      page: 1,
    };
    await driverStore.loadAllDriver(this.filter);
    this.driverList = driverStore.driverList;
  }

  async onPageChange(val: number) {
    this.filter.page = val;
    await driverStore.loadAllDriver(this.filter);
    this.driverList = driverStore.driverList;
  }

  onDelete(e: any) {
    this.$confirm(
      `This will permanently delete driver ${e.fullName}. Continue?`,
      {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }
    )
      .then(() => {
        driverStore.deleteDriver(e.id);
        this.$snotify.success("Driver successfully deleted");
      })
      .catch((e) => e);
  }

  async downloadExcel() {
    let query = `?page=&full_name=${this.filter.name}&profile__company_name=${this.filter.company_name}&profile__district=${this.filter.district}&profile__palika=${this.filter.palika}`;
    const action = Action.drivercsv;
    const sheet_path = await getApi(action);
    const link = document.createElement("a");
    link.setAttribute("href", sheet_path.excelSheetPath);
    link.click();
  }
}
